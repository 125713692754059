import AnxietyCards from "./anxietyCards";
import { MoreAnxiety } from "./moreAnxiety";

export default function Anxiety() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-8xl py-4 sm:px-0 sm:py-4 lg:px-0">
        <div className="relative isolate overflow-hidden bg-gray-900 px-0 pt-16 shadow-2xl sm:rounded-3xl sm:px-0 md:pt-24 lg:flex lg:gap-x-20 lg:px-20 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-20 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Anxiety
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              The WHO states that nearly 300 million people of all ages suffer
              from anxiety disorders which are around 3.6% of the global
              population. A study in South Africa revealed a lifetime prevalence
              of any anxiety disorder at around 15.8% in their country. In
              Nigeria, the lifetime prevalence of anxiety disorders was
              estimated to be around 3.3%, based on a population-based study.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
                href="/getinvolved"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Get involved
              </a>
              <a
                href="/"
                className="text-sm font-semibold leading-6 text-white"
              >
                Live Chat <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-32 ">
            <AnxietyCards />
          </div>
        </div>{" "}
        <MoreAnxiety />
      </div>
    </div>
  );
}
