const GuidanceIcons = () => {
  return (
    <div class="max-w-[85rem] py-4 lg:py-12 mx-auto">
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Identify Your Needs
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Research Providers
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Ask For Referrals
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Experience
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Therapeutic Approach
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-1 md:p-1 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Personal Compatibility
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Accessibility
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Initial Consultation
            </h3>
          </div>
        </div>
        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Cultural Awareness
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Cost and Insurance
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-1 md:p-1 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Confidentiality
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
          Take Care
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidanceIcons;
