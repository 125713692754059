export const WhyMentalIntroKinya = () => {
  return (
    <div class="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto bg-customized-6">
      <div class="mx-auto max-w-2xl mb-8 lg:mb-14 text-center">
        <h2 class="text-3xl lg:text-4xl text-gray-800 font-bold dark:text-gray-200 mb-8">
          Kuki Ubufasha bw'Ubuzima bwo mu Mutwe kurubyiruko rwa LGBTQI+ ari
          Ingenzi
        </h2>
        <p class="mt-3 text-gray-800 dark:text-gray-200 font-semibold">
          Gufasha urubyiruko rwa LGBTQI+ k’ubuzima bwabo bwo mumutwe ni ingenzi
          cyane kuko bahura nibibabazo bikomeye, banyura muri byinshi bitewe
          nabo baribo. Twihuse turebere hamwe impamvu ari ingenzi ko bagira
          uburenganzira kubufasha bw’ubuzima bwo mumutwe.
        </p>
      </div>

      <div class="mt-20 grid grid-cols-12 items-center gap-x-2 sm:gap-x-6 lg:gap-x-8">
        <div class="hidden md:block col-span-4 md:col-span-3">
          <img
            class="rounded-xl"
            src={require("../../../assets/Frame1.png")}
            alt="Image Description"
          />
        </div>

        <div class="col-span-4 md:col-span-3">
          <img
            class="rounded-xl"
            src={require("../../../assets/Frame2.png")}
            alt="Image Description"
          />
        </div>

        <div class="col-span-4 md:col-span-3">
          <img
            class="rounded-xl"
            src={require("../../../assets/Frame3.png")}
            alt="Image Description"
          />
        </div>

        <div class="col-span-4 md:col-span-3">
          <img
            class="rounded-xl"
            src={require("../../../assets/Frame4.png")}
            alt="Image Description"
          />
        </div>
      </div>
      <div class="mx-auto max-w-5xl grid grid-cols-12 gap-6 lg:gap-8 mt-8">
        <div class="col-span-6 sm:col-span-4 text-center">
          <svg
            class="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
          </svg>
          <div class="mt-2 sm:mt-6">
            <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
            Kwimenya no Kwiyakira
            </h3>
          </div>
        </div>

        <div class="col-span-6 sm:col-span-4 text-center">
          <svg
            class="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z" />
          </svg>
          <div class="mt-2 sm:mt-6">
            <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
            Gusebywa Ndetse no Guharabikwa
            </h3>
          </div>
        </div>

        <div class="col-span-6 col-start-4 sm:col-span-4 text-center">
          <svg
            class="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
          </svg>
          <div class="mt-2 sm:mt-6">
            <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
            Kwangwa n’Imiryango
            </h3>
          </div>
        </div>
      </div>

      <div className="relative grid gap-8 row-gap-5 mb-8 md:row-gap-8 lg:grid-cols-3 sm:grid-cols-1 mt-8">
        <div className="p-5 duration-300 transform border-4 border-customized-2 rounded shadow-sm hover:-translate-y-2">
          <div className="flex items-center justify-between mb-2">
            <p className="text-lg font-bold leading-5 text-gray-800">
            Urubyiruko rwa LGBTQI+ rushobora kugorwa nabo baribo bitewe nuko sosiyete ibafata, ubufasha bwo mu mutwe bubaha umwanya utuje aho bashobora kumva ndetse no kwiyakira badafite ubwoba cyangwa ikimwaro.
            </p>
          </div>
        </div>
        <div className="p-5 duration-300 transform border-4 border-customized-2 rounded shadow-sm hover:-translate-y-2">
          <div className="flex items-center justify-between mb-2">
            <p className="text-lg font-bold leading-5 text-gray-800">
            Urubyiruko rwa LGBTQI+ rukunze guhohoterwa, gufatwa nabi ndetse no kwirengagizwa bishobora gutuma bigunga, bahangayika ndetse banababara kugira ubufasha buhagije biborohereza guhangana nibihe bikomeye ndetse bakaba abanyembaraga.
            </p>
          </div>
        </div>
        <div className="p-5 duration-300 transform border-4 border-customized-2 rounded shadow-sm hover:-translate-y-2">
          <div className="flex items-center justify-between mb-2">
            <p className="text-lg font-bold leading-5 text-gray-800">
            Bamwe mu rubyiruko rwa LGBTQI+ bahezwa n’imiryango yabo ndetse ibi bishobora gutuma babura aho batura ,ubufasha bwo mumutwe bubagira inama  bukanabafasha muguhangana n'ibibibazo by’imiryango  yabo ndetse bikanabahuza nabantu babashigikiye,babafasha.
            </p>
          </div>
        </div>
        {/* <div className="p-5 duration-300 transform border-4 border-customized-8 rounded shadow-sm hover:-translate-y-2">
          <div className="flex items-center justify-between mb-2">
            <p className="text-lg font-bold leading-5">
              Your Tomorrow Starts Today{" "}
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};
