import React from "react";
import Check from "../components/tests/checkBoxTry";
import DepressionTest from "../components/tests/depression/depressionTest";
import TestsTabs from "../components/tests/testsTabs";

const Tests = () => {
  return (
    <div>
      <TestsTabs />
      {/* <DepressionTest /> */}
      {/* <Check/> */}
    </div>
  );
};

export default Tests;
