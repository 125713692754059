const GuidanceIconsKinya = () => {
  return (
    <div class="max-w-[85rem] py-4 lg:py-12 mx-auto">
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Menya Ibyo Ukeneye
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Kora Ubushaka shatsi
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Baza
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Reba Ubunya mwuga Bw’umuntu
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Ubuvuzi bworoshye kubugeraho
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-1 md:p-1 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Ibyiyumviro
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Ubuvuzi Bubasha Kugureraho
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Reba Uburyo Umurwayi Yakirwamo
            </h3>
          </div>
        </div>
        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Ubumenyi ku Muco
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Igiciro n’Ubwishi ngizi
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-1 md:p-1 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
            Ibanga
            </h3>
          </div>
        </div>

        <div class="h-36 sm:h-38 flex flex-col justify-center border border-gray-200 rounded-xl text-center p-4 md:p-5 dark:border-gray-700">
          <div class="mt-3">
            <h3 class="text-sm sm:text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
          Kwiyitaho
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidanceIconsKinya;
