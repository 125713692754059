import React from "react";
import TestsTabsKinya from "../../components/kinya/tests/testsTabsKinya";

const TestsKinya = () => {
  return (
    <div>
      <TestsTabsKinya />
      {/* <DepressionTest /> */}
      {/* <Check/> */}
    </div>
  );
};

export default TestsKinya;
